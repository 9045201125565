// 用户授权交互，获得返回数据
export function importJs(params: any) {
  return new Promise((resolve, reject) => {
    const windowTemp = window as any;
    switch (params.channel) {
      case "BEST_PAY":
      case "BESTPAY":
        windowTemp.BestpayHtml5.config();
        // params为商户分配的参数
        windowTemp.BestpayHtml5.User.userAuth(
          {
            scope: "mobile,user_info" || params.scope,
            clientId: "86002000365" || params.clientId,
            redirectUrl:
              "https://m.polyt.cn/#/other/oauth?theaterId=40" ||
              params.redirectUrl,
            merchantCode: "3178038020862116" || params.merchantCode,
            state: "05170001" || params.state,
          },
          function (obj: any) {
            resolve(obj);
          },
          function (obj: any) {
            reject(obj);
          },
          function (obj: any) {
            reject(obj);
          }
        );
        break;
      case "other":
        reject({});
        break;
    }
  });
}
