




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Toast } from "vant";
import { importJs } from "@/utils/otherCommonLogin";
import { loginCommon } from "@/utils/index";
@Component({})
export default class BestpayOauthMiddlePage extends Mixins(Mixin) {
  public code = "";
  public grantType = "";
  params = {};
  environment = ""; //环境
  created(): void {
    this.params = this.$route.query;
    importJs(this.params).then((res: any) => {
      console.log("importJs", res);
      this.code = res.code;
      this.grantType = res.grantType;
      this.oauthLogin();
    });
    this.environment = window.location.host; //当前环境
    console.log(
      this.environment,
      this.$route,
      this.$route.query,
      this.$route.query.distributionChannelId
    );
  }
  oauthLogin(): void {
    if (this.code) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      this.$api.oauth2Api.socialLogin.oauth(
        "BEST_PAY",
        { code: this.code, grantType: this.grantType },
        ({ data }) => {
          Toast.clear();
          let oauth2AccessToken = data.oauth2AccessToken;
          if (oauth2AccessToken) {
            loginCommon(oauth2AccessToken, this.$api);
            // 渠道分销地址，测试地址写死，其他环境从路径中获取
            this.$router.replace({
              path: "/channeldistribution/common",
              query: {
                distributionChannelId: "460",
                distributionSeriesId: "1689815972732473345",
                templateType: this.$route.query.channel,
              },
            });
          }
        }
      );
    }
  }
}
